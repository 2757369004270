"use strict";

$(document).ready(function () {
  var mex;

  if (window.jQuery) {
    mex = "Yeah! Happy coding";
  } else {
    mex = "Something doesn't work";
  }

  $("#esc").html(mex);
  console.info('all done');
});